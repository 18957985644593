.wrongCode, .rightCode {
  display: flex;
  background-color: #f91d1d;
  width: 65.3%;
  margin: 0 auto;
  padding: 1rem 2rem;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  color: white;
}
.wrongCode img, .rightCode img {
  width: 3rem;
  height: 3rem;
  margin: 0 0.5rem;
}
.wrongCode .textWrapper, .rightCode .textWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0.5rem;
}
.wrongCode .textWrapper h3, .rightCode .textWrapper h3 {
  margin: 0;
  font-size: 1.8rem;
  font-weight: bold;
}
.wrongCode .textWrapper p, .rightCode .textWrapper p {
  margin: 0;
  font-size: 1.4rem;
}

.rightCode {
  background-color: #68BE05;
}

.rtl {
  direction: rtl;
}
.rtl * {
  direction: rtl;
  text-align: right;
}