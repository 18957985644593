body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
body span {
  font-size: 1.4rem;
}

* {
  font-family: "Comfortaa", "Cairo", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: inherit;
  cursor: inherit;
}

.material-icons {
  font-family: "Material Icons", sans-serif !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}
@media only screen and (max-width: 117.5em) {
  html {
    font-size: 70%;
  }
}
@media only screen and (max-width: 96em) {
  html {
    font-size: 62.5%;
  }
}
@media only screen and (max-width: 90em) {
  html {
    font-size: 60%;
  }
}
@media only screen and (max-width: 68.75em) {
  html {
    font-size: 60%;
  }
}

.rc-notification-notice, .rc-notification-fade-enter-active {
  opacity: 1 !important;
  transition: all 300ms !important;
}
.rc-notification-notice.rc-notification-fade-leave-active, .rc-notification-notice.rc-notification-fade-enter, .rc-notification-fade-enter-active.rc-notification-fade-leave-active, .rc-notification-fade-enter-active.rc-notification-fade-enter {
  opacity: 0 !important;
}
.styles-module_wrongCode_3viGQ, .styles-module_rightCode_hFgFa {
  display: flex;
  background-color: #f91d1d;
  width: 65.3%;
  margin: 0 auto;
  padding: 1rem 2rem;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  color: white;
}
.styles-module_wrongCode_3viGQ img, .styles-module_rightCode_hFgFa img {
  width: 3rem;
  height: 3rem;
  margin: 0 0.5rem;
}
.styles-module_wrongCode_3viGQ .styles-module_textWrapper_2dEUx, .styles-module_rightCode_hFgFa .styles-module_textWrapper_2dEUx {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0.5rem;
}
.styles-module_wrongCode_3viGQ .styles-module_textWrapper_2dEUx h3, .styles-module_rightCode_hFgFa .styles-module_textWrapper_2dEUx h3 {
  margin: 0;
  font-size: 1.8rem;
  font-weight: bold;
}
.styles-module_wrongCode_3viGQ .styles-module_textWrapper_2dEUx p, .styles-module_rightCode_hFgFa .styles-module_textWrapper_2dEUx p {
  margin: 0;
  font-size: 1.4rem;
}

.styles-module_rightCode_hFgFa {
  background-color: #68BE05;
}

.styles-module_rtl_2XtXp {
  direction: rtl;
}
.styles-module_rtl_2XtXp * {
  direction: rtl;
  text-align: right;
}

/*# sourceMappingURL=index.2fd98134.chunk.css.map*/