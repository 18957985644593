body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
body span {
  font-size: 1.4rem;
}

* {
  font-family: "Comfortaa", "Cairo", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: inherit;
  cursor: inherit;
}

.material-icons {
  font-family: "Material Icons", sans-serif !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}
@media only screen and (max-width: 117.5em) {
  html {
    font-size: 70%;
  }
}
@media only screen and (max-width: 96em) {
  html {
    font-size: 62.5%;
  }
}
@media only screen and (max-width: 90em) {
  html {
    font-size: 60%;
  }
}
@media only screen and (max-width: 68.75em) {
  html {
    font-size: 60%;
  }
}

.rc-notification-notice, .rc-notification-fade-enter-active {
  opacity: 1 !important;
  transition: all 300ms !important;
}
.rc-notification-notice.rc-notification-fade-leave-active, .rc-notification-notice.rc-notification-fade-enter, .rc-notification-fade-enter-active.rc-notification-fade-leave-active, .rc-notification-fade-enter-active.rc-notification-fade-enter {
  opacity: 0 !important;
}